import Velocity from 'velocity-animate'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'title', 'button' ]
  static classes = [ 'unfocused', 'processing' ]

  connect () {
    super.connect()
    this.element.classList.add(this.unfocusedClass)
    this.input()

    this.element.addEventListener("turbo:submit-end", this.onSubmitEnd);
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd);
  }

  keypress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.capture()
    }
  }

  capture = e => {
    if (this.titleTarget.value) {
      this.element.requestSubmit();
      this.element.classList.add(this.processingClass)
    }
  }

  onSubmitEnd = (event) => {
    this.element.classList.remove(this.processingClass)
    if (event.detail.success) {
      this.titleTarget.value = ''
      this.buttonTarget.classList.add('hidden')
    }
    else {
      Velocity(this.element, { backgroundColor: '#ffcccc' }, { loop: 1 });
    }
  }

  input = e => {
    if (!this.titleTarget.value) {
      this.buttonTarget.classList.add('hidden')
    } else {
      this.buttonTarget.classList.remove('hidden')
    }
  }

  focus = e => {
    this.element.classList.remove(this.unfocusedClass)
  }

  blur = e => {
    this.element.classList.add(this.unfocusedClass)
  }
}