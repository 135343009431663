import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['items', 'template']
  static values = { rightList: Number, leftList: Number }

  insert = e => {
    let insertAfterElement = e.detail.insertAfterElement;

    // Make a todo node from the template and the event details
    let newTodoNode = this.templateTarget.content.firstElementChild.cloneNode(true);
    newTodoNode.querySelector('[data-todo-target="title"]').value = e.detail.title || '';
    newTodoNode.dataset.todoCloneIdValue = e.detail.cloneItemId;
    if (!e.detail.noAfter) {
      newTodoNode.dataset.todoAfterIdValue = insertAfterElement && insertAfterElement.dataset.todoIdValue != null ? insertAfterElement.dataset.todoIdValue : -1;
    }

    // Insert it
    if (insertAfterElement) {
      insertAfterElement.insertAdjacentElement('afterend', newTodoNode);
    } else {
      this.itemsTarget.insertAdjacentElement('afterbegin', newTodoNode);
    }
  }
}
