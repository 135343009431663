import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import '@turbo-boost/streams'
import "@fortawesome/fontawesome-free/js/all"
import * as ActiveStorage from "@rails/activestorage"
import "controllers"

FontAwesome.config.mutateApproach = 'sync'

Rails.start()
ActiveStorage.start()
