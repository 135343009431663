import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect();

    document.addEventListener('page:changed', this.page_changed);
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('page:changed', this.page_changed);
  }

  page_changed = event => {
    location.reload(true)
  }
}
