import Velocity from 'velocity-animate'
import ApplicationController from './application_controller'
import debounce from 'debounce'
import * as linkify from 'linkifyjs'

export default class extends ApplicationController {
  static targets = [ "checkbox", 'title', 'delete', 'transfer', 'options', 'isBlockstart', "pinned", "form", "proxyId", "afterId", "cloneId", "newIndex", "transferToList", "link" ]
  static values = { id: Number, expanded: Boolean, proxyId: String, afterId: Number, cloneId: Number, elementId: String, deleted: Boolean }
  static outlets = [ "list" ]

  connect () {
    super.connect();
    this.element[this.identifier] = this;

    this.submitting = false;

    this.debouncedRename = debounce(() => {
      if (!this.isProxy()) {
        this.requestSubmit();
      }
    }, 2000);

    if (this.isProxy()) {
      // take focus if this wasn't a drag/dropped item.
      if (!this.hasCloneIdValue || !Number.isFinite(this.cloneIdValue)) {
        this.titleTarget.focus();
      }
      this.onRename();

      this.element.id = 'a' + this.uuidv4(); //prepend 'a' to ensure it works as an html id
      this.elementIdValue = this.element.id; // trigger new todo check
    } else {
      this.replaceProxy();
    }

    this.updateLink();
  }

  disconnect() {
    super.disconnect();

    this.debouncedRename.clear();
  }

  deleteTargetConnected = (target) => {
    target.setAttribute('formmethod', 'delete');
  }

  formTargetConnected = (target) => {
    target.addEventListener("turbo:submit-end", this.onSubmitEnd);
    target.addEventListener("turbo:submit-start", this.onSubmitStart);
  }

  formTargetDisconnected = (target) => {
    target.removeEventListener("turbo:submit-end", this.onSubmitEnd);
    target.removeEventListener("turbo:submit-start", this.onSubmitStart);
  }

  isProxy = () => {
    return !this.hasIdValue;
  }

  afterIdValueChanged = () => {
    this.newTodoCheck();
  }

  elementIdValueChanged = () => {
    this.newTodoCheck();
  }

  newTodoCheck = () => {
    if (!this.isProxy() || this.submitting) {
      return;
    }

    if (this.hasAfterIdValue && Number.isFinite(this.afterIdValue) && this.hasElementIdValue) {
      const cloneId = this.hasCloneIdValue && Number.isFinite(this.cloneIdValue) ? this.cloneIdValue : null;
      this.proxyIdTarget.value = this.elementIdValue;
      this.afterIdTarget.value = this.afterIdValue;
      this.cloneIdTarget.value = cloneId;
      this.elementIdValue = undefined;
      this.requestSubmit();
    }
  }

  replaceProxy = () => {
    if (!this.hasProxyIdValue) {
      return;
    }
    
    const proxyElement = document.getElementById(this.proxyIdValue);
    if (proxyElement == null || proxyElement.id !== this.proxyIdValue) {
      // the proxy is in another client
      this.proxyIdValue = undefined;
      return;
    }

    const proxyTodo = proxyElement.todo;
    const focus = document.activeElement === proxyTodo.titleTarget;
    const selection = { start: proxyTodo.titleTarget.selectionStart, end: proxyTodo.titleTarget.selectionEnd };
    let title_text = proxyTodo.titleTarget.value;
    const deleted = proxyTodo.deletedValue;

    proxyTodo.element.style.display = "none";
    proxyTodo.element.remove();

    if (deleted) {
      // We're replacing a todo that has been marked for deletion.
      this.delete();
      return;
    }

    this.positionNextProxy();

    // Set properties on the new todo
    if (focus) {
      this.titleTarget.focus();
    }

    const titleChanged = this.titleTarget.value !== title_text;
    if (titleChanged) {
      this.titleTarget.value = title_text;
      this.rename();
    }

    this.titleTarget.setSelectionRange(selection.start, selection.end);
  }

  requestSubmit = (submitter) => {
    this.submitting = true;
    if (submitter) { // requestSubmit doesn't seem to work on iOS
      submitter.click();
    } else {
      this.formTarget.requestSubmit(submitter);
    }
  }

  onSubmitStart = (event) => {
    this.submitting = true;
    if (event.detail.formSubmission.submitter == this.deleteTarget) {
      this.deletedValue = true;
      this.debouncedRename.clear();
      this.animationPromise = Velocity(this.element, 'slideUp');
    }
  }

  onSubmitEnd = (event) => {
    this.submitting = false;
    if (this.hasTransferToListTarget) {
      this.transferToListTarget.value = null;
    }
    this.positionNextProxy();
    
    if (this.deletedValue && this.hasIdValue) {
      if (this.animationPromise) {
        this.animationPromise.then(() => {
          this.element.remove();
        }).catch(() => {
          this.element.remove();
        });
      } else {
        this.element.remove();
      }
    }

    if (!event.detail.success) {
      console.log("form submission failed")
      return; 
      // TODO: Handle submission errors and aborted forms
      // In case of aborted submissions, the submission may still have been made
      // i.e. we may just need a way to refresh the page, or indicate it's possibly out of date. 
      // `deleting` can still succeed just fine when the form is aborted.
    }
  }

  positionNextProxy = () => {
    // If the next sibling is a proxy todo, it can now create/position itself.
    const nextTodoEl = this.element.nextElementSibling
    if (this.hasIdValue && nextTodoEl && nextTodoEl.todo && nextTodoEl.todo.isProxy() && !nextTodoEl.todo.hasAfterIdValue) {
      nextTodoEl.todo.afterIdValue = this.idValue;
    }    
  }

  updateLink = () => {
    const links = linkify.find(this.titleTarget.value, 'url')

    if (links.length > 0) {
      this.linkTarget.href = links[0].href
      this.linkTarget.style.display = '';
    } else {
      this.linkTarget.style.display = 'none';
    }
  }

  complete(event) {
    if (this.hasIdValue) {
      this.requestSubmit();
    }
  }

  togglePin(event) {
    if (this.hasIdValue) {
      this.requestSubmit();
    }
  }

  toggleIsBlockstart(event) {
    if (this.hasIdValue) {
      this.requestSubmit();
    }
  }

  rename = () => {
    this.onRename();
    if (this.hasIdValue) {
      this.debouncedRename();
    }
  }

  onRename() {
    if (this.titleTarget.value === '') {
      this.checkboxTarget.classList.remove('border');
    }
    else {
      this.checkboxTarget.classList.add('border');
    }

    this.updateLink();
  }

  flushRename() {
    if (this.hasIdValue) {
      if (this.titleTarget.value.endsWith('-')) {
        this.titleTarget.value = this.titleTarget.value.substr(0, this.titleTarget.value.length - 1);
        this.isBlockstartTarget.checked = !this.isBlockstartTarget.checked;
        this.debouncedRename();
      }

      this.debouncedRename.flush();
    }
  }

  // TODO: Can separate paste logic into it's own controller
  paste = event => {
    event.preventDefault();
    let text = (event.originalEvent || event).clipboardData.getData('text/plain');
    text = text.split('\r\n').join(' ');
    text = text.split('\n').join(' ');
    text = text.split('\r').join(' ');
    text = text.trim();
    window.document.execCommand('insertText', false, text);
  }

  transferLeft = (event) => {
    event.preventDefault();
    if (this.hasIdValue) {
      this.debouncedRename.flush();
      this.transferToListTarget.value = this.listOutlet.leftListValue;
      this.requestSubmit();
    }
  }

  transferRight = (event) => {
    event.preventDefault();
    if (this.hasIdValue) {
      this.debouncedRename.flush();
      this.transferToListTarget.value = this.listOutlet.rightListValue;
      this.requestSubmit();
    }
  }

  positionItem = (newIndex) => {
    if (this.isProxy()) {
      return;
    }

    this.newIndexTarget.value = newIndex;
    this.requestSubmit();
  }

  shouldCloneDragged = () => {
    return this.pinnedTarget.checked;
  }

  dragAdd = () => {
    let insertAfterElement = this.element.previousElementSibling;
    while (insertAfterElement && !('todoIdValue' in insertAfterElement.dataset)) {
      insertAfterElement = insertAfterElement.previousElementSibling;
    }
    const e = new CustomEvent('insertTodo', { bubbles: true, detail: { cloneItemId: this.idValue, title: this.titleTarget.value, insertAfterElement } });
    this.element.dispatchEvent(e);
    this.element.remove();
  }

  keypress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.flushRename();
      this.listOutlet.insert({ bubbles: true, detail: { insertAfterElement: this.element, noAfter: this.submitting || this.isProxy() } })
    }
  }

  keydown(e) {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.focusNextTodo();
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.focusPrevTodo();
    }
    if (e.key === 'Delete' && this.titleTarget.value === '') {
      e.preventDefault();
      this.delete();
      this.focusNextTodo();
    }
    if (e.key === 'Backspace' && this.titleTarget.value === '') {
      e.preventDefault();
      this.delete();
      this.focusPrevTodo(true);
    }
  }

  delete = () => {
    if (this.hasIdValue && !this.deletedValue) {
      this.debouncedRename.clear();
      this.animationPromise = Velocity(this.element, 'slideUp');
      this.requestSubmit(this.deleteTarget);
    }
    this.deletedValue = true;
  }

  toggleExpanded() {
    this.expandedValue = !this.expandedValue;
    if (this.expandedValue) {
      Velocity(this.optionsTarget, 'slideDown', { display: "" });
    } else {
      Velocity(this.optionsTarget, 'slideUp');
    }
  }

  optionsTargetConnected() {
    if (this.expandedValue) {
      this.optionsTarget.style.display = '';
    } else {
      this.optionsTarget.style.display = 'none';
    }
  }

  focusNextTodo() {
    const row = this.element.nextElementSibling
    if (row) {
      row.querySelector("input[type='text']").focus();
    }
  }

  focusPrevTodo(cursorAtEnd) {
    const row = this.element.previousElementSibling
    if (row) {
      const input = row.querySelector("input[type='text']")
      if (input) {
        input.focus();
        if (cursorAtEnd) {
          input.setSelectionRange(input.value.length, input.value.length);
        }
      }
    }
  }
}
