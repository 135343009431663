import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  static values = { name: String }
  static targets = [ "appendField", "positionField" ]

  connect () {
    super.connect()
    this.element[this.identifier] = this;

    const options = {
      group: { name: this.nameValue, revertClone: true },
      removeCloneOnHide: false,
      onAdd: this.dragAdd,
      draggable: '.craziness'
    }

    this.sortable = new Sortable(this.element, options)
  }

  disconnect() {
    super.disconnect()

    this.sortable.destroy()
  }

  dragAdd = event => {
    const todoId = event.item.dataset.todoIdValue;
    this.appendFieldTarget.value = todoId;
    if (this.hasPositionFieldTarget) {
      this.positionFieldTarget.value = null;
    }
    this.element.requestSubmit();
    Velocity(event.item, 'slideUp', { complete: function(elements) { elements.forEach(e => e.remove()) } });
  }

  positionItem = newIndex => {
    if (this.hasAppendFieldTarget) {
      this.appendFieldTarget.value = null;
    }
    this.positionFieldTarget.value = newIndex;
    this.element.requestSubmit();
  }

  shouldCloneDragged = () => {
    return false;
  }
}
