import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    if (window.visualViewport) {
      this.viewport = window.visualViewport
      this.viewport.addEventListener('resize', this.onViewportResize);
      this.viewport.addEventListener('scroll', this.onViewportResize);
      this.onViewportResize()
    }
  }

  disconnect() {
    super.disconnect()
    if (this.viewport) {
      this.viewport.removeEventListener('resize', this.onViewportResize)
      this.viewport.removeEventListener('scroll', this.onViewportResize)
    }
  }

  onViewportResize = () => {
    // console.log(this.viewport)
    // console.log(this.element)
    this.element.style.top =  `calc(env(safe-area-inset-top) + ${this.viewport.offsetTop}px)`
    this.element.style.height = `calc(${this.viewport.height}px - env(safe-area-inset-top))`
    if (document.activeElement && document.activeElement.tagName !== "BODY") {
      document.activeElement.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" })
    }
  }
}