import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  static values = { name: String }
  static targets = [ "positionField" ]

  connect () {
    super.connect()
    this.element[this.identifier] = this;
  }

  disconnect() {
    super.disconnect()
  }

  dragAdd = event => {
    // Not possible to add to an existing habit
  }

  positionItem = newIndex => {
    this.positionFieldTarget.value = newIndex;
    this.element.requestSubmit();
  }

  shouldCloneDragged = () => {
    return false;
  }
}
