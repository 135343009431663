import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { id: String }
  static targets = [ "form" ]

  connect () {
    super.connect()
    if (!this.hasIdValue) {
      this.idValue = this.uuidv4();
    }

    addEventListener("turbo:before-stream-render", this.beforeStreamRender);
  }

  disconnect () {
    removeEventListener("turbo:before-stream-render", this.beforeStreamRender);
  }

  formTargetConnected(target) {
    target.addEventListener("formdata", this.onFormData);
  }

  formTargetDisconnected(target) {
    target.removeEventListener("formdata", this.onFormData);
  }

  beforeStreamRender = (event) => {
    const fallbackToDefaultActions = event.detail.render
    
    event.detail.render = (streamElement) => {
      const targetEl = document.getElementById(streamElement.target);
      if (this.element.contains(targetEl) && streamElement.getAttribute('except') == this.idValue) {
        return;
      }

      fallbackToDefaultActions(streamElement);
    };
  }

  onFormData = (event) => {
    event.formData.set('crap-id', this.idValue);
  }
}
