import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect();

    document.addEventListener('sortable:dragstart', this.dragstart);
    document.addEventListener('sortable:dragend', this.dragend);
  }

  disconnect() {
    document.removeEventListener('sortable:dragstart', this.dragstart);
    document.removeEventListener('sortable:dragend', this.dragend);
  }

  dragstart = () => {
    this.element.classList.remove('snap-x');
  }

  dragend = () => {
    this.element.classList.add('snap-x');
  }
}
