import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
    this.element.addEventListener('focus', this.focus)
    this.element.addEventListener('keyup', this.keyup)
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener("focus", this.focus);
    this.element.removeEventListener("keyup", this.keyup);
  }

  focus = (event) => {
    event.preventDefault()
  }

  keyup = (event) => {
    if (event.keyCode == 32) {
      this.element.querySelector('input[type="checkbox"]').click();
    }
  }
}