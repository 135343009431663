import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  static values = { name: String, itemController: String }

  connect () {
    super.connect()

    const options = {
      group: { name: this.nameValue, pull: this.pullDragged, revertClone: true },
      animation: this.data.get('animation') || 150,
      handle: this.data.get('handle') || undefined,
      removeCloneOnHide: false,
      onStart: this.dragStart,
      onEnd: this.dragEnd,
      onAdd: this.dragAdd,
    }

    this.sortable = new Sortable(this.element, options)
  }

  disconnect() {
    super.disconnect()

    this.sortable.destroy()
  }

  dragStart = event => {
    this.shouldCloneDragged = event.item[this.itemControllerValue].shouldCloneDragged();
    document.dispatchEvent(
      new CustomEvent('sortable:dragstart', { bubbles: true, cancelable: false, detail: {} })
    )
  }

  dragEnd = event => {
    if (event.from === event.to && event.newIndex !== event.oldIndex) {
      event.item[this.itemControllerValue].positionItem(event.newIndex);
    } else {
      // handled by the dragAdd method
    }

    document.dispatchEvent(
      new CustomEvent('sortable:dragend', { bubbles: true, cancelable: false, detail: {} })
    )

  }

  dragAdd = event => {
    event.item[this.itemControllerValue].dragAdd();
  }

  pullDragged = (to, from) => {
    if (typeof to.options !== 'undefined' && to.options.group.name !== from.options.group.name) {
      return false
    }

    return this.shouldCloneDragged ? 'clone' : true
  }
}
