import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "dropdown", "focus" ]

  toggle(event) {
    this.dropdownTargets.forEach(element => {
      if ('toggleClass' in element.dataset) {
        const toggleClass = element.dataset.toggleClass
        element.dataset.toggleClass = element.className
        element.className = toggleClass
      } else if (element.classList.contains('hidden')) {
        element.classList.remove('hidden')
        if ('toggleFocus' in element.dataset) {
          if (element.dataset.toggleFocus) {
            element.scrollIntoView()
            element.focus()
          }
        }
      } else {
        element.classList.add('hidden')
      }
    });

    if (this.hasFocusTarget) {
      const e = this.focusTarget
      if (!!( e.offsetWidth || e.offsetHeight || e.getClientRects().length )) {
        e.focus()
      }
    }

    event.stopPropagation();
  }
}
